import React, { PropTypes } from 'react';
import classNames from 'bbc-morph-classnames';

function themingClass(theme) {
  if (!theme) {
    return '';
  }
  return `gs-t-${theme}`;
}

function getCommentsId(assetIdFromProps, assetUri) {
  const assetId = assetIdFromProps || assetUri.match(/\b\d+/);

  if (assetUri.indexOf('/sport') > -1) {
    return `__CPS_${assetId}`;
  }

  return `__CPS__${assetId}`;
}

const renderContent = props => {
  const { commentsCount, textClasses, iconClasses, offScreenText, assetId, assetUri } = props;
  const dnaCommentsId = getCommentsId(assetId, assetUri);
  const commentsCountId = `dna-comment-count_${dnaCommentsId}`;
  const textClassName = classNames('gs-o-bullet__text', commentsCountId, textClasses);
  const iconClassName = classNames(
    'gs-o-bullet__icon',
    'gel-icon',
    'gs-c-comment-count__icon',
    iconClasses,
  );

  return React.Children.only(
    <span>
      <span className={iconClassName}>
        <svg aria-hidden="true" focusable="false" viewBox="0 0 32 32">
          <path d="M0 0v32l8-8h24V0H0zm4 10h18v4H4v-4zm24 10H4v-4h24v4zm0-12H4V4h24v4z" />
        </svg>
      </span>
      <span className={textClassName} data-comments-id={dnaCommentsId} id={commentsCountId}>
        {commentsCount}
      </span>
      <span className="gs-u-vh"> {offScreenText}</span>
    </span>,
  );
};

const renderGSCommentIcon = (content, { commentsLink, anchorClasses, theme }) => {
  const wrapperClassName = classNames(themingClass(theme), [
    'gs-o-bullet',
    'gs-o-bullet-',
    'gs-c-comment-count',
    anchorClasses,
  ]);

  if (!commentsLink) {
    return <span className={wrapperClassName}>{content}</span>;
  }

  return (
    <a className={wrapperClassName} href={commentsLink}>
      {content}
    </a>
  );
};

const GSCommentIcon = props => {
  const commentIconContent = renderContent(props);
  return renderGSCommentIcon(commentIconContent, props);
};

GSCommentIcon.displayName = 'GrandStand__CommentIcon';

GSCommentIcon.propTypes = {
  anchorClasses: PropTypes.string,
  assetId: PropTypes.string,
  assetUri: PropTypes.string,
  commentsCount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  commentsLink: PropTypes.string,
  iconClasses: PropTypes.string,
  offScreenText: PropTypes.string,
  textClasses: PropTypes.string,
  theme: PropTypes.string,
};

GSCommentIcon.defaultProps = {
  anchorClasses: '',
  assetId: '',
  assetUri: '',
  commentsCount: '',
  commentsLink: '',
  iconClasses: '',
  offScreenText: 'Comments',
  textClasses: '',
  theme: '',
};

export default GSCommentIcon;
